import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "InstarVision Surveillance Center",
  "path": "/Software/Android/InstarVision/Wizard/Install/IN-9010-9020/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Um mit unserer Android App InstarVision auf Ihre IP-Kamera zuzugreifen, empfehlen wir Ihnen, zuerst die neueste Version aus dem App Store herunterzuladen. Verwenden Sie das Suchwort Instar, um unsere App im Geschäft zu finden.",
  "image": "./P_SearchThumb_InstarVision_Android.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Android.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Android-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "android-app-installation-wizard",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#android-app-installation-wizard",
        "aria-label": "android app installation wizard permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Android App Installation Wizard`}</h2>
    <h3 {...{
      "id": "add-a-new-p2p-camera-in-9010-fhd--in-9020-fhd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-a-new-p2p-camera-in-9010-fhd--in-9020-fhd",
        "aria-label": "add a new p2p camera in 9010 fhd  in 9020 fhd permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a New P2P Camera (`}<em parentName="h3">{`IN-9010 FHD & IN-9020 FHD`}</em>{`)`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d68a9dd268973067a428e67de8dfb8a8/81315/Android_Wizard_Installation_9020_9010_d01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.56521739130436%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACgElEQVQ4y42Ty27TQBSGvatK1Q0r2tq1m6qhbWKfM2cunvFlEjuhF9WJkzRtd+2GJ2CJEIIX4AFggcSaV+EhYMeGZ0BxWqi6gH46Go1+za/z6xyNAwDtdnvtjpWVla2tLc/zXNfz/J1Wq1WWpeu6q6ur6+vra2trm5ubvu+7rhvs7jntdnt3d7ff7yOi7/utVssPAn/b227tbeDR8/39IPC1NkVRpGlqrRVSbnueH+xs8MrpNABAGIad+3Q7ne6tgohxHEspVQMiHh4edsLIYQ3YwB6CRMQYk1L2er08z621vV4vSRLOORE5eAc0LC9LJYqiNE3n8/nFxcV8Pr+8vLy6uprNZnVd39zcDIdD564lMVpwezZZAICIxuPx2dlZXdfT6bSu6/F4PBqN6rpWSjmLJ1E3S02/Z22W5nne7/fzPGcICJFSqqqqyWQyGo3Oz89ns9lkMplOp1VVZVnmMK6YPWX5CeXHZE+VWgyGGEYiibgmYkJIzrkQYjAYHB8fa62JiDc4XMZpeZoUJ0lvaIuhtVZrDRABj5HxP5MAxDAMu90uADDGlrLDlYnLShZVnJVayaSBIUTcRDxGiIQQUgjRNJdC5HmepKkxZmGOY3V6cmSzVOvYGJMkiTaGIQDFSAIRpSAwRWheSM60NoOyGJaFtRYRncW84CHL3TXFBCdSCcUZ52S0VlmJ+UmWJoyxv3v+B0SMEyEi5yRMTulAykWox5iBuCChgHFgRFwopZbhHte5uycOfDoI6CDgBz4dtpb6I8wQ7tSvN26+BNcfg+tPm9efvasPwDgi/NfMWNR99vLr6ttfW+++u+9/PHnz8+mrb0ASIXpU7Nt/Aou6z28K2N6lM3xqVAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d68a9dd268973067a428e67de8dfb8a8/e4706/Android_Wizard_Installation_9020_9010_d01.avif 230w", "/en/static/d68a9dd268973067a428e67de8dfb8a8/d1af7/Android_Wizard_Installation_9020_9010_d01.avif 460w", "/en/static/d68a9dd268973067a428e67de8dfb8a8/7f308/Android_Wizard_Installation_9020_9010_d01.avif 920w", "/en/static/d68a9dd268973067a428e67de8dfb8a8/e1c99/Android_Wizard_Installation_9020_9010_d01.avif 1380w", "/en/static/d68a9dd268973067a428e67de8dfb8a8/6e1e4/Android_Wizard_Installation_9020_9010_d01.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d68a9dd268973067a428e67de8dfb8a8/a0b58/Android_Wizard_Installation_9020_9010_d01.webp 230w", "/en/static/d68a9dd268973067a428e67de8dfb8a8/bc10c/Android_Wizard_Installation_9020_9010_d01.webp 460w", "/en/static/d68a9dd268973067a428e67de8dfb8a8/966d8/Android_Wizard_Installation_9020_9010_d01.webp 920w", "/en/static/d68a9dd268973067a428e67de8dfb8a8/445df/Android_Wizard_Installation_9020_9010_d01.webp 1380w", "/en/static/d68a9dd268973067a428e67de8dfb8a8/87a8c/Android_Wizard_Installation_9020_9010_d01.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d68a9dd268973067a428e67de8dfb8a8/81c8e/Android_Wizard_Installation_9020_9010_d01.png 230w", "/en/static/d68a9dd268973067a428e67de8dfb8a8/08a84/Android_Wizard_Installation_9020_9010_d01.png 460w", "/en/static/d68a9dd268973067a428e67de8dfb8a8/c0255/Android_Wizard_Installation_9020_9010_d01.png 920w", "/en/static/d68a9dd268973067a428e67de8dfb8a8/b1001/Android_Wizard_Installation_9020_9010_d01.png 1380w", "/en/static/d68a9dd268973067a428e67de8dfb8a8/81315/Android_Wizard_Installation_9020_9010_d01.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d68a9dd268973067a428e67de8dfb8a8/c0255/Android_Wizard_Installation_9020_9010_d01.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[01]`}</strong>{` Start by entering the `}<strong parentName="li">{`Cameras`}</strong>{` menu and select the `}<strong parentName="li">{`Installation Wizard`}</strong>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`[02]`}</strong>{` You can find the `}<strong parentName="li">{`QR Code`}</strong>{` in the `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1080p_Series/Network/Remote_Access/"
        }}>{`WebUI`}</a>{` (A), printed on the label of your camera (B) or on the package.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/74427693f16a89f507f5f9940d23b591/81315/Android_Wizard_Installation_9020_9010_d02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACw0lEQVQ4y3WSz0/aYBzGe9d4VklaxYsHiCASoEBRUHrYbRHJ4oKepqjLBCcwKlBa3v6yv9+CpS2Z/8p2WqKnZbsv2T+yCFmWxfo5fPO+hyfP83zfFwkGgyiKxuPxZDKJouji4tKM5eXlQCCwvr6eSqVWVlbm5+cXFhbm5uaWlpZQFA0EAsFgEDk4OCiXy5VK5ejoqFwul0r75b+USvuhUAjDsFwuh+M4hmFra2urq6srUzAMQ8bjsed5rus6jvM03PFk4k0mk/v7e9u+y+fziUQiGo2Gw+HQ/4TDYcQwDAihZVkQQtM0p1dzOBw6jmMYOkmSuVxua2srEolEn4GY5j/x7DAajcbjses6EJrFYhHH8c3NzY2NjcgzEFmWNE21bdtxHM97Cvx5iuu6EMKZc6FQiMViz/UIxw1uZUnTVcMwLMuy7bvxFM/zTPPJOZPJxOPxqB8ITfcGgOV4IEmiotyapmlZ1qzzTEwQxIudqRuKYRiGZQSBkyRB1/VZedu2DcMgSTKTyezs7PjHZlmG40C/TwNuIIqCosi6rum6PhqNVFWdxX5xYdZoBC3I8wAAVhB4URRUVdE0bTgcyrJMkiRBEDiOR6NRH/H3X7+/PDyIksCwfQAAL3CKImuaBiGUZblYLGazWYIgfM2Rbz9+fn18BICl6c6AZQBgRZFXVQVCU5Kk2cI2pvg4031auhU5MOjRXYbpDwYsAKyqKoah8TzY29vDcZwgiFgs5rPt6+urbq/D83y73Wo0rlutFkVRqqoqitztdgqFQjKZTKVS/p3r9Vqz2eh2O/V6rVa7rF/Vrj7W+32apulmq7W7u5tOpyORiH/sWu2y2Wy0258oqt2mnmav17uhbpqNxmXtQz6/k05ntre3/d/54uLs/fvzi4vzs7NqtXpyenpyfHz4tvL6XfXw4M2rbDZNEEQymfD9nn8AFXp7GpoZ11QAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/74427693f16a89f507f5f9940d23b591/e4706/Android_Wizard_Installation_9020_9010_d02.avif 230w", "/en/static/74427693f16a89f507f5f9940d23b591/d1af7/Android_Wizard_Installation_9020_9010_d02.avif 460w", "/en/static/74427693f16a89f507f5f9940d23b591/7f308/Android_Wizard_Installation_9020_9010_d02.avif 920w", "/en/static/74427693f16a89f507f5f9940d23b591/e1c99/Android_Wizard_Installation_9020_9010_d02.avif 1380w", "/en/static/74427693f16a89f507f5f9940d23b591/6e1e4/Android_Wizard_Installation_9020_9010_d02.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/74427693f16a89f507f5f9940d23b591/a0b58/Android_Wizard_Installation_9020_9010_d02.webp 230w", "/en/static/74427693f16a89f507f5f9940d23b591/bc10c/Android_Wizard_Installation_9020_9010_d02.webp 460w", "/en/static/74427693f16a89f507f5f9940d23b591/966d8/Android_Wizard_Installation_9020_9010_d02.webp 920w", "/en/static/74427693f16a89f507f5f9940d23b591/445df/Android_Wizard_Installation_9020_9010_d02.webp 1380w", "/en/static/74427693f16a89f507f5f9940d23b591/87a8c/Android_Wizard_Installation_9020_9010_d02.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/74427693f16a89f507f5f9940d23b591/81c8e/Android_Wizard_Installation_9020_9010_d02.png 230w", "/en/static/74427693f16a89f507f5f9940d23b591/08a84/Android_Wizard_Installation_9020_9010_d02.png 460w", "/en/static/74427693f16a89f507f5f9940d23b591/c0255/Android_Wizard_Installation_9020_9010_d02.png 920w", "/en/static/74427693f16a89f507f5f9940d23b591/b1001/Android_Wizard_Installation_9020_9010_d02.png 1380w", "/en/static/74427693f16a89f507f5f9940d23b591/81315/Android_Wizard_Installation_9020_9010_d02.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/74427693f16a89f507f5f9940d23b591/c0255/Android_Wizard_Installation_9020_9010_d02.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[03]`}</strong>{` Use the app to scan the QR code from your camera or camera package. If your camera does not have a QR code, please manually select the camera model in the next step.`}</li>
      <li parentName="ul"><strong parentName="li">{`[04]`}</strong>{` Click `}<strong parentName="li">{`Next`}</strong>{` to continue.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/88a7ddcc16547a30461cfa79d84a23eb/81315/Android_Wizard_Installation_9020_9010_d03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.56521739130436%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACaklEQVQ4y42SzW7TQBDHfbSUnktE4g8MLUkc78zu7HrtxMnGDi5Jo4iiQqXeW4k3AAkegQtv0Rs98Sg9wqugehMrQgjxO81+/Gdm/zvOaDQqiqLX67mue3R05Lpur9fr9/tP+70gOomiqKqqfr/vum6n03Fdt9vt+r7veV50MnBOT0/DMDTGcM49zwvDMAiCwPeC6KSLr18OBkEQzI0RQvi+H0VRc8EPwmddsXHiOB6Px6xBSqmUGo/HcbMbj5k9BYAkSeI/SJjDGxBRCDGfz2ezmV0iIkdsT3EfH4BOeyCEsDERKaWklFYGALjHxrDHsQspZZqmsoFzTkRpmhKR7agV23S2Eud8V3k6nRZFIYTIskwphYhKKSEEERljqqqq67qqquVyWZZlXddlWWqtHZu7Tck5tzWtGAA4R2NMWZbzhrIsF4uFJMEBHNskHkANUkoSAoRi0zNontY6x1jCKGNy4tgnHTohGogIALTOLq+uLy/fvbm4WK/X2+12tVohwFhXo6x+FCuliMgKbMOPRZpERGJZmmph8jxD5PaatQ6xMSzP86IosiybTCZaayJqOwROQ5oN04pxQoTWc+AEQj6KtdbWkjzP0zRt/wYAlNbbt+82F+9X6/PN+fn5ZrPdbo0xo7Qa5aud27O5mZlFPil0NuEkkYv2/8/qVwszV0rphizLpCQmUkbZbkhSGE7FMOcDip/TKBTxi7Z4kiSMMTgAATkCR7BDAv7V1+Pbe+/mzru5O7797l1/a0Yb2qn6Kzvxk9v7zsdf/pcH7/ND59PP4w8/ms4B/4mzH3kmIGEsAZZwSDgw/A9+Axvb2CFu0oOJAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/88a7ddcc16547a30461cfa79d84a23eb/e4706/Android_Wizard_Installation_9020_9010_d03.avif 230w", "/en/static/88a7ddcc16547a30461cfa79d84a23eb/d1af7/Android_Wizard_Installation_9020_9010_d03.avif 460w", "/en/static/88a7ddcc16547a30461cfa79d84a23eb/7f308/Android_Wizard_Installation_9020_9010_d03.avif 920w", "/en/static/88a7ddcc16547a30461cfa79d84a23eb/e1c99/Android_Wizard_Installation_9020_9010_d03.avif 1380w", "/en/static/88a7ddcc16547a30461cfa79d84a23eb/6e1e4/Android_Wizard_Installation_9020_9010_d03.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/88a7ddcc16547a30461cfa79d84a23eb/a0b58/Android_Wizard_Installation_9020_9010_d03.webp 230w", "/en/static/88a7ddcc16547a30461cfa79d84a23eb/bc10c/Android_Wizard_Installation_9020_9010_d03.webp 460w", "/en/static/88a7ddcc16547a30461cfa79d84a23eb/966d8/Android_Wizard_Installation_9020_9010_d03.webp 920w", "/en/static/88a7ddcc16547a30461cfa79d84a23eb/445df/Android_Wizard_Installation_9020_9010_d03.webp 1380w", "/en/static/88a7ddcc16547a30461cfa79d84a23eb/87a8c/Android_Wizard_Installation_9020_9010_d03.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/88a7ddcc16547a30461cfa79d84a23eb/81c8e/Android_Wizard_Installation_9020_9010_d03.png 230w", "/en/static/88a7ddcc16547a30461cfa79d84a23eb/08a84/Android_Wizard_Installation_9020_9010_d03.png 460w", "/en/static/88a7ddcc16547a30461cfa79d84a23eb/c0255/Android_Wizard_Installation_9020_9010_d03.png 920w", "/en/static/88a7ddcc16547a30461cfa79d84a23eb/b1001/Android_Wizard_Installation_9020_9010_d03.png 1380w", "/en/static/88a7ddcc16547a30461cfa79d84a23eb/81315/Android_Wizard_Installation_9020_9010_d03.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/88a7ddcc16547a30461cfa79d84a23eb/c0255/Android_Wizard_Installation_9020_9010_d03.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[05]`}</strong>{` Select whether your camera should be connected via `}<strong parentName="li">{`WiFi`}</strong>{` or if you will be using an Ethernet cable.`}</li>
      <li parentName="ul"><strong parentName="li">{`[06]`}</strong>{` Click `}<strong parentName="li">{`Next`}</strong>{` to continue.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1a7d782169ba1f3e2b8735a964928af2/81315/Android_Wizard_Installation_9020_9010_d04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACuUlEQVQ4y12SzW7bRhDHeWkKAQbSg4GylkhRYqzaIndnZ5e7S3nJJc2D5Cofhl3JRnLPKUAQoEDRY5DYCZAeAhToc/QN+hI55RmKHhrkVBRFQW0kK/2BWC53ODP/2RlvNBpVVdXr9Tqdzs7OTqfT6Xa7QRDsdfcG8SiKoqZpgiDYWH3fD8LWGo8OvNFoFIahtRYRwzAcDodhvx8EvSAafg3Tg8NxGAZFUQghgiAYDAb91hqE/b4v7nmMMb0iyzJEBAAhRJZlUikusvF4DABKKSklXyOE4MiTlHhaa2NMnufGmPl8PpvNrLVHR0dlWU4mE8ZYnud1XZdlWa1xn4yxNrM7Oj4+bppmOp3WdV1VlbWWUloUxXK5XCwWp6en3684XzOdTj1jzOXl5fn5+WKxcOvZ2dlyhQsvpcyyjHNujLHWOtmutFZ2VVVlWRZFYa01xjhhdV1rrSmlsAWDz/AAgK4ghABAURTGmPY/xty6ARhLEQER1yeee3HOtdaIGMdxFEWEEM75tidlTAHcHx/Ig29JmrJVBM95TiaTk5MTRIyiaHd31/f9TVrOuZRSKqWQzUki0wQoxW1nRHQ9zLJMCOFK2BZlrZVaH1J6mKZJmiZJ0taMiEIIdwFOKsBNXS5onuez2axpmv39/cFgMFxBCGkzK6UmK7TW/7skxlg7T2tpYgtE9Fwn6+Pmu7v3VD4Bhgx5+2zh3Nzw3rRtU3Op0UrCkxjHsUhinu5/lr3tMGWMIqPtpt1vWgX0zvxpsPz5zsV1fPEqXL4ZPvhplfxTCYACuCQoCZPAJfDsxhmB7D75/Yurf795/Zf/+sOtl/989cM7wKx1Ro5k7D/+7csXf/tXf+y9+rPz/OPtH9+DUAzopyEBnoFQhCvCNQgFXK4VIwKJ7z7rPvp18PDt8NHb3sNf+otrQMEY/AcYZOJ0oDhiEAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1a7d782169ba1f3e2b8735a964928af2/e4706/Android_Wizard_Installation_9020_9010_d04.avif 230w", "/en/static/1a7d782169ba1f3e2b8735a964928af2/d1af7/Android_Wizard_Installation_9020_9010_d04.avif 460w", "/en/static/1a7d782169ba1f3e2b8735a964928af2/7f308/Android_Wizard_Installation_9020_9010_d04.avif 920w", "/en/static/1a7d782169ba1f3e2b8735a964928af2/e1c99/Android_Wizard_Installation_9020_9010_d04.avif 1380w", "/en/static/1a7d782169ba1f3e2b8735a964928af2/6e1e4/Android_Wizard_Installation_9020_9010_d04.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1a7d782169ba1f3e2b8735a964928af2/a0b58/Android_Wizard_Installation_9020_9010_d04.webp 230w", "/en/static/1a7d782169ba1f3e2b8735a964928af2/bc10c/Android_Wizard_Installation_9020_9010_d04.webp 460w", "/en/static/1a7d782169ba1f3e2b8735a964928af2/966d8/Android_Wizard_Installation_9020_9010_d04.webp 920w", "/en/static/1a7d782169ba1f3e2b8735a964928af2/445df/Android_Wizard_Installation_9020_9010_d04.webp 1380w", "/en/static/1a7d782169ba1f3e2b8735a964928af2/87a8c/Android_Wizard_Installation_9020_9010_d04.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1a7d782169ba1f3e2b8735a964928af2/81c8e/Android_Wizard_Installation_9020_9010_d04.png 230w", "/en/static/1a7d782169ba1f3e2b8735a964928af2/08a84/Android_Wizard_Installation_9020_9010_d04.png 460w", "/en/static/1a7d782169ba1f3e2b8735a964928af2/c0255/Android_Wizard_Installation_9020_9010_d04.png 920w", "/en/static/1a7d782169ba1f3e2b8735a964928af2/b1001/Android_Wizard_Installation_9020_9010_d04.png 1380w", "/en/static/1a7d782169ba1f3e2b8735a964928af2/81315/Android_Wizard_Installation_9020_9010_d04.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1a7d782169ba1f3e2b8735a964928af2/c0255/Android_Wizard_Installation_9020_9010_d04.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[07]`}</strong>{` Click `}<strong parentName="li">{`Next`}</strong>{` to continue.`}</li>
      <li parentName="ul"><strong parentName="li">{`[08]`}</strong>{` Once your camera is connected click on `}<strong parentName="li">{`Search`}</strong>{` and the app will detect all INSTAR cameras on your local network.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/01c8ba663d7df45a354673440c8f16a6/81315/Android_Wizard_Installation_9020_9010_d05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.56521739130436%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACo0lEQVQ4y43RS2/TQBAAYB8bqRIql5I0sUlIkzT2el/e3dhr7zpruw8K4tBISFBEOcCpFaGc+AU9gbj1XyBx74mHhAT8nJ6LbBMoAqF+msPKq9mZ8Vie50kpW61Wo9FYXl5uNBrtylp7zemt93o9k2Xtdntpaam+bTabtm13Op3e+sgaDAbdbldrTSl1HKfb7dqOY3faTm/YRJvD0dBxHKU1xrjT6ZS3tmPbtnOze4PsWuPx2PM8IQSllBCCMSYVtwTGFQBAEAS4Qil1Xbf86noW55wxprU2xmRZlqbptGQE5zygjDEhyqNeMMZMJhPGGGfMUkptVxhjAABY832IAxSaJJametEYs7e3N5vNiqKoyxhjrDiOi6LY2tqKoqh8byFggkdKcC6lFEJACBljlFIIIQDA933P88rKdat5ntcd1hgXPExkFBljhBAIobqn+kAIiaLISpLkdmU6ncJffradaRVv7+wwxoJLKKW86siqRg+UUiqJtUpUkqSpTrXmLGABFUKEYSilxH+BEFoQYZ/wMJKxShOdyjhJlJJx4lPhQySlNMaEYYj+xUKYgkk2VLN+9rifPriV7vfNo6GaeeGmj8hkIvI8V0pdrkkI0WV7qYUQxNBfmX9rnFw4r897b84bJxfX518x8iH0wzDUWsdxTBZohRPMfGBV9fEwP+jvzjfuHI3vHvV3XwzzA4QwQohzHpYizkW5RjHBlAUYw/zO+N5+nYwwGBFvgN0yiDcgYFSvJIqi6XRaGFWoMEvCIpURwwkaoSfHg1en1mJ4jDD5HVXZOt/3xq37b68dfm4dnrWOzlYOP64+fYcgxu6Ghf4PY4JA5+Hpyvy7ffzBfvlp5fmX1WfvIQmqv30F2HfLQbwRckcEjDDYWKzqqvCfUSb/ACNs5j29lz/+AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/01c8ba663d7df45a354673440c8f16a6/e4706/Android_Wizard_Installation_9020_9010_d05.avif 230w", "/en/static/01c8ba663d7df45a354673440c8f16a6/d1af7/Android_Wizard_Installation_9020_9010_d05.avif 460w", "/en/static/01c8ba663d7df45a354673440c8f16a6/7f308/Android_Wizard_Installation_9020_9010_d05.avif 920w", "/en/static/01c8ba663d7df45a354673440c8f16a6/e1c99/Android_Wizard_Installation_9020_9010_d05.avif 1380w", "/en/static/01c8ba663d7df45a354673440c8f16a6/6e1e4/Android_Wizard_Installation_9020_9010_d05.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/01c8ba663d7df45a354673440c8f16a6/a0b58/Android_Wizard_Installation_9020_9010_d05.webp 230w", "/en/static/01c8ba663d7df45a354673440c8f16a6/bc10c/Android_Wizard_Installation_9020_9010_d05.webp 460w", "/en/static/01c8ba663d7df45a354673440c8f16a6/966d8/Android_Wizard_Installation_9020_9010_d05.webp 920w", "/en/static/01c8ba663d7df45a354673440c8f16a6/445df/Android_Wizard_Installation_9020_9010_d05.webp 1380w", "/en/static/01c8ba663d7df45a354673440c8f16a6/87a8c/Android_Wizard_Installation_9020_9010_d05.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/01c8ba663d7df45a354673440c8f16a6/81c8e/Android_Wizard_Installation_9020_9010_d05.png 230w", "/en/static/01c8ba663d7df45a354673440c8f16a6/08a84/Android_Wizard_Installation_9020_9010_d05.png 460w", "/en/static/01c8ba663d7df45a354673440c8f16a6/c0255/Android_Wizard_Installation_9020_9010_d05.png 920w", "/en/static/01c8ba663d7df45a354673440c8f16a6/b1001/Android_Wizard_Installation_9020_9010_d05.png 1380w", "/en/static/01c8ba663d7df45a354673440c8f16a6/81315/Android_Wizard_Installation_9020_9010_d05.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/01c8ba663d7df45a354673440c8f16a6/c0255/Android_Wizard_Installation_9020_9010_d05.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[09]`}</strong>{` Select your camera from the list. If your camera does not show choose to `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPad/DDNS/"
        }}>{`add it manually`}</a>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`[10]`}</strong>{` You will now be asked to enter the admin login of your camera. If you haven't set it up before this will be the default usermane `}<strong parentName="li">{`admin`}</strong>{` and password `}<strong parentName="li">{`instar`}</strong>{`. This can be changed later inside the app.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6e272e820e1a0088e79182fbe6ba22b5/81315/Android_Wizard_Installation_9020_9010_d06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACaElEQVQ4y8WRS2vUUBSAs1E6WnfajmRuZjKdmU4Tc9+PJPfmMcmUCi12GujYrRZduHNlRexKRHHhr+hKXAhu/Cm6kv4GcSdKJhQqiNSVH4fL5Ry+c7nnWJ7nGWNs2261WsvLy0tLS7ZtA9CxO3avP3RdtyxLAEBTbbVa7Xa72+0CANaGY2s0GvV6vSzLMMaO47iu6zhdB3S6/eFNcnt9fQwASJKEEHJWdQAAjtNt023L87wgCKSUjDFCCKW0OYMg2PB8hJBYIKUkC9gCzjmmzOKcK6Wyc6Rpmue5WrTjnCdJEsexMWZra2tnZ8cYY7TWcayktCaTSVVVs9msqqq9vb35fH5wcFBV1f7+flEUlFKllJQSIUQp5ZwTRqkQTErKuRWGYZ7nRVForZtyA2MsCAIhxGQyKYqCEIIQwggxKaTWymgeKitN0+l0ujmdah0jCDFCCEEEIWMsiiIpZSMbY2oZYx6GKklUYngYWsaYsiw3p2Wepc2oOKOMEqVUHMec8zRNtdac81rFmHIuokjEMRPCqh+C8MaDD1eOTp3jz53jL1efnq48+oQJhbDeQlmWURRBCBcuJgSLOFLG1HKT6s7frB6+dw5PnMOTlfvvwN23uP4klFJmWWZ0xAgiKKgDQ6GEiON6YI1M/SHzenSjTzf69cUfYIybJderyjdROPFF4os0UBlJyt9kTCgm7FzQJl0P2B9ff/jx0sufq6++tV9/v/zix7Wjr5BIjOCZ/BcQXJ/ec3efj3afjGZH7p1na9uPUd0dXUDGmARj6q8Rb0C8AfUH9NaoyV9Ixpgs/nUu/kX+M/9P/gVupeCtjm/CpAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6e272e820e1a0088e79182fbe6ba22b5/e4706/Android_Wizard_Installation_9020_9010_d06.avif 230w", "/en/static/6e272e820e1a0088e79182fbe6ba22b5/d1af7/Android_Wizard_Installation_9020_9010_d06.avif 460w", "/en/static/6e272e820e1a0088e79182fbe6ba22b5/7f308/Android_Wizard_Installation_9020_9010_d06.avif 920w", "/en/static/6e272e820e1a0088e79182fbe6ba22b5/e1c99/Android_Wizard_Installation_9020_9010_d06.avif 1380w", "/en/static/6e272e820e1a0088e79182fbe6ba22b5/6e1e4/Android_Wizard_Installation_9020_9010_d06.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6e272e820e1a0088e79182fbe6ba22b5/a0b58/Android_Wizard_Installation_9020_9010_d06.webp 230w", "/en/static/6e272e820e1a0088e79182fbe6ba22b5/bc10c/Android_Wizard_Installation_9020_9010_d06.webp 460w", "/en/static/6e272e820e1a0088e79182fbe6ba22b5/966d8/Android_Wizard_Installation_9020_9010_d06.webp 920w", "/en/static/6e272e820e1a0088e79182fbe6ba22b5/445df/Android_Wizard_Installation_9020_9010_d06.webp 1380w", "/en/static/6e272e820e1a0088e79182fbe6ba22b5/87a8c/Android_Wizard_Installation_9020_9010_d06.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6e272e820e1a0088e79182fbe6ba22b5/81c8e/Android_Wizard_Installation_9020_9010_d06.png 230w", "/en/static/6e272e820e1a0088e79182fbe6ba22b5/08a84/Android_Wizard_Installation_9020_9010_d06.png 460w", "/en/static/6e272e820e1a0088e79182fbe6ba22b5/c0255/Android_Wizard_Installation_9020_9010_d06.png 920w", "/en/static/6e272e820e1a0088e79182fbe6ba22b5/b1001/Android_Wizard_Installation_9020_9010_d06.png 1380w", "/en/static/6e272e820e1a0088e79182fbe6ba22b5/81315/Android_Wizard_Installation_9020_9010_d06.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6e272e820e1a0088e79182fbe6ba22b5/c0255/Android_Wizard_Installation_9020_9010_d06.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[11]`}</strong>{` Please choose a name for your camera.`}</li>
      <li parentName="ul"><strong parentName="li">{`[10]`}</strong>{` Click `}<strong parentName="li">{`Next`}</strong>{` to continue.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      